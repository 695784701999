exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deadlines-js": () => import("./../../../src/pages/deadlines.js" /* webpackChunkName: "component---src-pages-deadlines-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-open-source-js": () => import("./../../../src/pages/open-source.js" /* webpackChunkName: "component---src-pages-open-source-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-pages-thesis-js": () => import("./../../../src/pages/thesis.js" /* webpackChunkName: "component---src-pages-thesis-js" */),
  "component---src-templates-paper-jsx": () => import("./../../../src/templates/Paper.jsx" /* webpackChunkName: "component---src-templates-paper-jsx" */),
  "component---src-templates-person-jsx": () => import("./../../../src/templates/Person.jsx" /* webpackChunkName: "component---src-templates-person-jsx" */),
  "component---src-templates-talk-jsx": () => import("./../../../src/templates/Talk.jsx" /* webpackChunkName: "component---src-templates-talk-jsx" */)
}

